import React from 'react'
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

export default {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
            return (
                node.data.target.fields.file["en-US"].contentType === "video/mp4"
                    ?
                    <video
                        className="f-article-content-video"
                        src={node.data.target.fields.file['en-US'].url}
                        controls
                        type="video/mp4"
                    />
                    :
                    <img className="f-article-content-img" src={node.data.target.fields.file['en-US'].url} alt={node.data.target.fields.description && node.data.target.fields.description['en-US']} />
            )
        },
        [INLINES.HYPERLINK]: (node, next) => {
            return (node.data.uri.includes('https://jwp.io') ?
                <iframe
                    frameBorder="0"
                    sandbox="allow-popups allow-same-origin allow-scripts"
                    scrolling="no"
                    src={node.data.uri}
                    allowFullScreen
                    className="f-iframe-naviapp"
                />
                :
                <a href={node.data.uri} rel="noopener noreferrer" target="_blank">{next}</a>)
        }
    }
}