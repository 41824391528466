import React from "react";
import "./card.scss";
import Button from "../button/button";
import Img from "gatsby-image";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import siteUrl from "../../../utils/siteUrl";
import { userType } from "../../../utils/getUserType";

const Card = ({ content, type, preSlug }) => {
  const options1 = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, next) => (
        <p className={`f-card__paragraph ${type}`}>{next}</p>
      ),
    },
  };
  const {
    imagenCard,
    tituloCard,
    descripcionCard,
    redireccionBotonCard,
    Slug,
    tags,
    redireccionExterna,
    tipoDeContenido,
  } = content;

  let externalLink =
    redireccionExterna?.length > 5 &&
    tipoDeContenido[0].nombreCategoria === "Juegos";

  const handleTags = (tags) => {
    let result = ""
    if(tags) {
      result = tags.reduce((acc, elemento)=>{
        return `${acc}${acc !== "" ? " - " : ""} ${elemento.nombreTag}`
      }, "")
    }
    return result;
  };

  const handleDataLayerCard = () => {
    (window.dataLayer = window.dataLayer || []);
    if (type === "territories") { 
        window.dataLayer.push({
          event: "ga_event",
          category: "Home",
          action: "Click - Contenidos bienestar",
          label: tituloCard,
          user_type: userType(),
          value: "",
        })
     } else if (type === "article") { 
        window.dataLayer.push({
          event: "ga_event",
          category: "Home",
          action: "Click - Lo nuevo",
          label: tituloCard + " - " + handleTags(tags),
          user_type: userType(),
          value: "",
        })
    } 
  }

  return (
    <a
      className={`f-card ${type}`}
      href={
        type === "article"
          ? externalLink
            ? redireccionExterna
            : siteUrl + "/" + preSlug + Slug
          : siteUrl + "/" + redireccionBotonCard
      }
      onClick={ handleDataLayerCard }
    >
      <div className={`f-card__img ${type}`}>
        <Img
          className="image-fluid"
          loading="lazy"
          itemProp="image"
          fluid={imagenCard?.fluid}
          alt={imagenCard?.description}
        />
      </div>
      {type === "article" && (
        <ul className="f-card__tags">
          {tags &&
            tags.map((data, index) => {
              if (index <= 2) {
                return (
                  <li key={index} className="f-card__tags-tag">
                    {data.nombreTag}
                  </li>
                );
              }
            })}
        </ul>
      )}
      <div className={`f-card__description ${type}`}>
        <h3 className="f-card__title">{tituloCard}</h3>
        {/*<p className="f-card__paragraph">{description}</p>*/}
        {documentToReactComponents(JSON.parse(descripcionCard.raw), options1)}
      </div>
      <div className="f-card__button">
        <Button text="Leer más" />
      </div>
    </a>
  );
};

export default Card;
