import React from "react";
import './content-home.scss'
import SectionBanner from "../section-banner/section-banner";
import SectionWellnessContent from "../section-wellness-content/section-wellness-content";
import SectionTheNew from "../section-the-new/section-the-new";
import SectionCalendar from "../section-calendar/section-calandar";
import { SectionModal } from "./section-modal/section-modal";

const ConTentHome = ({ isModal }) => {

  return (
    <>
      {isModal &&
        <SectionModal isModal={isModal} />
      }

      <SectionBanner />
      <SectionWellnessContent />
      <SectionCalendar />
      <SectionTheNew />
    </>
  )

}

export default ConTentHome
