import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import useMobile from "../../../../shared/hooks/useMobile";
import ReactPlayer from "react-player";
import Modal from "../../../../shared/components/organisms/modal/modal";
import "./section-modal.scss";

export const SectionModal = ({}) => {
  const isMobile = useMobile();

  const { allContentfulFamiliaInstitucionalModalInformativo } = useStaticQuery(
    graphql`
      query InfoModalQuery {
        allContentfulFamiliaInstitucionalModalInformativo {
          nodes {
            isActive
            titulo
            urlVideo
            redireccionModal
            imagenModal {
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
              }
              file {
                url
                contentType
              }
              description
            }
          }
        }
      }
    `
  );

  const info = allContentfulFamiliaInstitucionalModalInformativo.nodes[0];

  const [showModal, setShowModal] = useState(true);
  const [videoSize, setVideSize] = useState(0);

  const handleRizeImg = () => {
    if (document.getElementById("modalVideoIframe")) {
      let size = document.getElementById("modalVideoIframe").clientWidth;

      if (size === 0) {
        setTimeout(() => {
          handleRizeImg();
        }, 1000);
      }

      const result = (421 * size) / 748;

      setVideSize(result);
    }
  };

  useEffect(() => {
    if (document.getElementById("modalVideoIframe")) {
      handleRizeImg();
      window.addEventListener("resize", handleRizeImg);
    }
  }, []);
  const dataLayerHome= function datalayer(){
    const urlActual = window.location;
    if(urlActual.hash===""){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({event:'ga_event',
      category:'PopUp',
      action:'Click',
      label:'¡Quiero responder!',
      value:''});
    }
  }
  return (
    <>
      {showModal && info.isActive && (
        <Modal setShowModal={setShowModal}>
          <div id="modalVideoIframe" className="f-modal-home">
            {info.urlVideo?.includes("https") ? (
              <div className="f-modal-home-video">
                {info.urlVideo?.includes("jwplatform") ? (
                  <video
                    className="f-section-banner__video"
                    src={info.urlVideo}
                    controls
                    onPlay={() => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: "ga_event",
                        category: window.location.pathname,
                        action: "Video - Play",
                        label: info.descripcionVideo,
                        value: "",
                      });
                    }}
                    onPauseCapture={() => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: "ga_event",
                        category: window.location.pathname,
                        action: "Video - Pause",
                        label: info.descripcionVideo,
                        value: "",
                      });
                    }}
                    onEndedCapture={() => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: "ga_event",
                        category: window.location.pathname,
                        action: "Video - Complete",
                        label: info.descripcionVideo,
                        value: "",
                      });
                    }}
                  />
                ) : info.urlVideo?.includes("youtube") ? (
                  <ReactPlayer
                    className="f-section-banner__video"
                    width="484px"
                    height="65%"
                    url={info.urlVideo}
                    controls
                    onPlay={() => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: "ga_event",
                        category: window.location.pathname,
                        action: "Video - Play",
                        label: info.descripcionVideo,
                        value: "",
                      });
                    }}
                    onPause={() => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: "ga_event",
                        category: window.location.pathname,
                        action: "Video - Pause",
                        label: info.descripcionVideo,
                        value: "",
                      });
                    }}
                    onEnded={() => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: "ga_event",
                        category: window.location.pathname,
                        action: "Video - Complete",
                        label: info.descripcionVideo,
                        value: "",
                      });
                    }}
                  />
                ) : (
                  <iframe
                    frameBorder="0"
                    scrolling="no"
                    src={info.urlVideo}
                    sandbox="allow-popups allow-same-origin allow-scripts"
                    className="f-modal-home-video-iframe"
                    allowFullScreen
                    style={{ height: videoSize }}
                  />
                )}
              </div>
            ) : info.imagenModal[0].fluid &&
              info.imagenModal[0].contentType === "image/png" ? (
              <Img
                loading="lazy"
                itemProp="image"
                className="fluid-image"
                fluid={info.imagenModal[isMobile ? 1 : 0].fluid}
                alt={info.imagenModal[isMobile ? 1 : 0].description}
                onClick={()=>{dataLayerHome()}}
              />
            ) : (
              <img
                src={info.imagenModal[isMobile ? 1 : 0].file.url}
                alt={info.imagenModal[0].desription}
                onClick={()=>{dataLayerHome()}}
              />
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
