import React from "react";
import './section-wellness-content.scss'
import Card from "../../../shared/components/atoms/card/card";
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import ArrowSlider from "../../../shared/components/atoms/arrow-slider/arrow-slider";
import {graphql, useStaticQuery} from "gatsby";


const SectionWellnessContent = () => {

  const {allContentfulFamiliaInstitucionalHome} = useStaticQuery(
    graphql`
        query CArds_Query {
            allContentfulFamiliaInstitucionalHome {
                nodes {
                    tituloContenidosBienestar
                    cardsContenidoBienestar {
                        tituloCard
                        imagenCard {
                            fluid {
                                base64
                                aspectRatio
                                sizes
                                src
                                srcSet
                            }
                            description
                        }
                        descripcionCard {
                            raw
                        }
                        tituloBotonCard
                        redireccionBotonCard
                    }
                }
            }
        }


    `
  )

  const contentInfo = allContentfulFamiliaInstitucionalHome.nodes[0]


  const settingsSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <ArrowSlider direction="right"/>,
    prevArrow: <ArrowSlider direction="left"/>,
    swipe: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }

  return (
    <section className="f-section-wellness-content">
      <div className="f-section-wellness-content__wrapper">
        <h2 className="f-section-wellness-content__title">{contentInfo.tituloContenidosBienestar}</h2>

        <div className="f-section-wellness-content__cards">
          <Slider {...settingsSlider}>
            {contentInfo.cardsContenidoBienestar &&
            contentInfo.cardsContenidoBienestar.map((data, index) => {
              return (
                <Card content={data} key={index} type="territories"/>
              )
            })
            }
          </Slider>
        </div>

      </div>
    </section>
  )


}

export default SectionWellnessContent
