import React, { useState, useEffect } from "react";
import './content-results-search.scss'
import PaginationArticles from './../../../shared/components/molecules/pagination-articles/pagination-articles'
import useMobile from "../../../shared/hooks/useMobile";
import { ContentSearch } from "../../../shared/components/molecules/content-search/content-search";
import { useStaticQuery, graphql } from 'gatsby'
import LikeService from "../../../shared/services/like-service";
import Loading from "../../../shared/components/organisms/loading/loading";

const ContentResultsSearch = () => {

  const { allContentfulFamiliaInstitucionalHeader } = useStaticQuery(
    graphql`
        query SearchResultsQuery {
            allContentfulFamiliaInstitucionalHeader {
                nodes {
                    tituloBuscador
                    tituloBuscadorSinResultados
                    tituloResultadosPara
                    descripcionBusquedaArrojada
                    descripcionResultados
                    descripcionResultadosPara
                    descripcionSeEncontraron
                }
            }
        }
    `
  )

  const infoText = allContentfulFamiliaInstitucionalHeader.nodes[0]

  const [info, setInfo] = useState({ search: '', results: [] })
  const isMobile = useMobile();


  const handleCapitalize = (word) => {
    return word != '' ? word[0].toUpperCase() + word.slice(1) : ''
  }

  useEffect(() => {
    if (localStorage.getItem('SEARCH_RESULTS')) {
      const results = JSON.parse(localStorage.getItem('SEARCH_RESULTS'))

      setInfo(results)
    }
  }, [])

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    //Añadir al localStorage los articulos a los que el usuario le ha dado like
    let tokenId = JSON.parse(localStorage.getItem("TOKEN_INFO")).idToken

    let config = {
      headers: {
        Authorization: tokenId,
      },
    }

    LikeService.getLikesUser(config).then(res => {
      if (res.status === 200) {
        localStorage.setItem('LOCAL_LIKES_ARTICLES', JSON.stringify(res.data))
        setIsLoaded(true)
      } else {
        localStorage.setItem('LOCAL_LIKES_ARTICLES', '[]')
        setIsLoaded(true)
      }
    }).catch(e=> {
      localStorage.setItem("LOCAL_LIKES_ARTICLES", "[]");
      setIsLoaded(true);
    })
  }, [])

  return (
    <section className="f-results-search" >
      <div id="scroll-top" />
      {
        isMobile &&
        <div className="f-results-search-mobile">
          <div className="f-results-search-mobile-title"> {infoText.tituloBuscador} </div>
          <ContentSearch
            labelResults={infoText.tituloBuscadorSinResultados}
          />
        </div>
      }

      {info &&
        <>
          <div className="f-results-search-title"> {infoText.tituloResultadosPara} <span>{handleCapitalize(info.search)} </span> </div>

          {
            info.results.length ?
              <div className="f-results-search-subtitle"> {infoText.descripcionBusquedaArrojada} <span> {info.results.length} {infoText.descripcionResultados} </span> </div>
              :
              <div className="f-results-search-subtitle"> {infoText.descripcionSeEncontraron} <span> {info.results.length} {infoText.descripcionResultadosPara} “{handleCapitalize(info.search)}” </span> </div>
          }

          {isLoaded && (<PaginationArticles articles={info.results} isSearch={true} />) }
          {!isLoaded && <Loading />}

        </>
      }
    </section>
  )

}

export default ContentResultsSearch
