import React, { useState } from 'react'
import './content-list-pages.scss'
import Button from './../../atoms/button/button'
import { userType } from '../../../utils/getUserType';
import { ItemListPages } from '../../atoms/item-list-pages/item-list-pages';

export const ContentListPages = ({ info, isLoadMore }) => {
    const amountItems = 6
    const [isActive, setisActive] = useState()
    const [cantItems, setCantItems] = useState(amountItems)



    const hanldeItems = () => {
        setCantItems(cantItems + amountItems)
    }

    const handleActive = (index) => {
        index != isActive ? setisActive(index) : setisActive(null)
    }

    return (
        <section className="f-list-pages">
            {info?.preguntas?.map((data, index) => {
                if (isLoadMore) {
                    if (cantItems > index) {
                        return (
                            <ItemListPages
                                data={data}
                                onActive={handleActive}
                                index={index}
                                isActive={isActive}
                                key={index}
                            />
                        )
                    }
                } else {
                    return (
                        <ItemListPages
                            data={data}
                            onActive={handleActive}
                            index={index}
                            isActive={isActive}
                            key={index}
                        />
                    )
                }
            })}

            {isLoadMore && info?.preguntas?.length > cantItems &&
                <Button 
                    text={info.tituloBoton} 
                    action={ () => {
                        hanldeItems
                        (window.dataLayer = window.dataLayer || []);
                        window.dataLayer.push({
                          event: "ga_event",
                          category: "Centro de ayuda",
                          action: "Click - Preguntas frecuentes",
                          label: "Cargar más",
                          user_type: userType(),
                          value: "",
                        });
                        }
                    } />
            }
        </section>
    )
}   
