import React, {useEffect, useState} from "react"
import siteUrl from "../../../utils/siteUrl"
import "./breadcrumb.scss"

const Breadcrumb = props => {

  const [path, setPath] = useState("")

  useEffect(() => {
    setPath(window.location.pathname)
  }, [])

  const camelize = (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.charAt(0).toUpperCase() : word;
    }).replace(/\s+/g, "").replace(/-/g, " ")
  }

  const urlTransform = () => {
    var elements = path.split("/")
    elements.shift()
    if (elements[elements.length - 1] === "") elements.pop()

    return elements
  }

  return (

    <div
      className="f-breadcrumb-container">
      <div className="f-breadcrumb-element">
        <a className="f-link f-link-breadcrumb" href={siteUrl + "/"}>Inicio</a>
      </div>

      {urlTransform().map((element, index) => {

        var fullPath = ""
        urlTransform().map((elementPath, indexPath) => indexPath <= index ? fullPath += elementPath + "/" : "")

        if (index < urlTransform().length - 1) {

          return (
            <React.Fragment key={`breadcrumb${index}`}>
              <span className="f-breadcrumb-separator">>></span>
              <div key={index} className="f-breadcrumb-element">
                <a className="f-link f-link-breadcrumb"
                   href={siteUrl + "/" + fullPath}>{camelize(element)}</a>
              </div>
            </React.Fragment>
          )

        } else {
          return (
            <React.Fragment key={`breadcrumb${index}`}>
              <span className="f-breadcrumb-separator">>></span>
              <div key={index} className="f-breadcrumb-element">
                <span className="f-current-site">{camelize(element)}</span>
              </div>
            </React.Fragment>

          )
        }
      })}
    </div>
  )
}

export default Breadcrumb
