import React, {useState, useEffect} from "react"
import "./pagination.scss"


const Pagination = ({totalPages = 0, onClickNext, onClickPrev, onClickPage, currentPage}) => {

  const [pagesNumber, setPagesNumber] = useState([]);

  useEffect(() => {
    const pages = [];
    [...Array(totalPages).keys()].map((_, index) => {
      if(index <4){
        pages.push(index+1)
      }
    })
    setPagesNumber(pages)
    }, [totalPages]);


  const clickPrev = () => {
    if (currentPage > 1) {
      onClickPrev()
      handleChangePages('prev')

    }
  }
  const clickNext = () => {
    if (currentPage < totalPages) {
      onClickNext()
      handleChangePages('next')

    }
  }

  //Validacion para cargar mas paginas en la paginacion
  const handleChangePages = action => {

    if(action === 'prev'){
      if(currentPage === pagesNumber[0]){
        let newPages = pagesNumber;
        newPages.pop();
        newPages.unshift(pagesNumber[0]-1)
        setPagesNumber(newPages)
      }

    }else if(action === 'next'){
      if(currentPage === pagesNumber[3]){
        let newPages = pagesNumber;
        newPages.shift();
        newPages.push(currentPage+1)
        setPagesNumber(newPages)
      }
    }
  }

  return (
    <ul className="pagination">
      <li className="pagination__direction left">
        <button onClick={clickPrev} disabled={(currentPage === 1)}>
          <i className={'fas fa-chevron-left'}></i>
        </button>
      </li>
      {
        pagesNumber.map((numberPage, index) => {
          return (
            <li key={index}>
              <button
                className={`pagination__item ${currentPage === (numberPage) ? "active" : ""}`}
                onClick={() => {
                  onClickPage(numberPage)
                }}
              >
                {numberPage}
              </button>
            </li>
          )

        })
      }
      <li className="pagination__direction right">
        <button onClick={clickNext} disabled={(currentPage === totalPages)}>
          <i className={'fas fa-chevron-right'}></i>
        </button>
      </li>
    </ul>

  )

}

export default Pagination
