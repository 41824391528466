import React from "react"
import SEO from "../shared/components/atoms/seo/seo"
import Layout from "../shared/components/organisms/layout/layout"
import PageNotFound from "../components/organisms/page-not-found/page-not-found";
import {graphql} from "gatsby"


const NotFoundPage = (props) => {
  const {allContentfulFamiliaInstitucionalPagina404} = props.data

  return (
    <Layout>
      <SEO
        title={allContentfulFamiliaInstitucionalPagina404.nodes[0].metaTitulo}
        description={allContentfulFamiliaInstitucionalPagina404.nodes[0].metaDescripcion.metaDescripcion}
      />
      <PageNotFound/>
    </Layout>
  )
}

export const query = graphql`
    query MyQuery {
        allContentfulFamiliaInstitucionalPagina404 {
            nodes {
                metaTitulo
                metaDescripcion
            }
        }
    }

`

export default NotFoundPage
