// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-politicas-de-proteccion-a-la-informacion-jsx": () => import("./../../../src/pages/politicas-de-proteccion-a-la-informacion.jsx" /* webpackChunkName: "component---src-pages-politicas-de-proteccion-a-la-informacion-jsx" */),
  "component---src-pages-resultados-de-busqueda-jsx": () => import("./../../../src/pages/resultados-de-busqueda.jsx" /* webpackChunkName: "component---src-pages-resultados-de-busqueda-jsx" */),
  "component---src-pages-terminos-y-condiciones-jsx": () => import("./../../../src/pages/terminos-y-condiciones.jsx" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-jsx" */)
}

