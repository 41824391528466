import React from "react";
import './button.scss'

const Button = ({ text, action, gradient, disabled = false, url }) => {

  if (url) {
      return <a 
        className={'f-button f-button--link ' + gradient} 
        href={url}
        target={url.includes("htpps") ? "_blank" : "_self"}
      >
        {text}
      </a>
  }
  
  return (
      <button className={`f-button ${gradient} ${disabled ? 'disabled' : ''}`} onClick={action}>
        {text}
      </button>
  )
}

export default Button
