import React, { useEffect, useState } from "react"
import "./footer.scss"
import { useStaticQuery, graphql } from "gatsby"
import siteUrl from "../../../utils/siteUrl"
import { userType } from "../../../utils/getUserType"
import Img from "gatsby-image";

const Footer = ({ country }) => {

    const [expertLines, setExpertLines] = useState();

    const { allContentfulFamiliaInstitucionalFooter } = useStaticQuery(
        graphql`
            query FooterQuery {
                allContentfulFamiliaInstitucionalFooter {
                    nodes {
                        tituloPoliticasDeProteccion
                        tituloTerminosYCondiciones
                        redireccionPoliticasDeProteccion
                        redirecionTerminosYCondiciones
                        lineaTelefonica {
                            nombreLineaTelefonica
                            numeroLineaTelefonica
                            pais
                        }
                        redesSociales {
                            redireccionRedSolicial
                            iconoRedSocial {
                                file {
                                    url
                                }
                                description
                                fluid {
                                  aspectRatio
                                  base64
                                  src
                                  sizes
                                  srcSet
                                }
                            }
                        }
                    }
                }
            }              
        `
    )

    const info = allContentfulFamiliaInstitucionalFooter.nodes[0]

    const goToUrl = (url) => {
        window.location.href = siteUrl + url
    }

    const formatText = (text) => {
        return text.normalize("NFD").replace(/[^a-zA-Z 0-9.]+/g, "").toLowerCase().trim()
    }

    useEffect(() => {
        country = formatText(country);

        const lines = info.lineaTelefonica.filter(item => {
            return formatText(item.pais) === country
        })

        setExpertLines(lines)


    }, [country])
    
    return (
      <footer className="f-footer">
        <div className="f-footer-container">
          {expertLines && expertLines[0]?.numeroLineaTelefonica && (
            <div className="f-footer-lines">
              {expertLines.map((line, index) => {
                return (
                  <div key={index} className="f-footer-lines-cont">
                    {index != 0 ? (
                      <div className="f-footer-lines-cont-separator"></div>
                    ) : (
                      ""
                    )}

                    <div className="f-footer-lines-cont-title">
                      {" "}
                      {line.nombreLineaTelefonica}{" "}
                    </div>
                    <div className="f-footer-lines-cont-number">
                      {" "}
                      {line.numeroLineaTelefonica}{" "}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <div className="f-footer-social">
            {info.redesSociales.map((social, index) => {
              return (
                <a
                  key={index}
                  href={social.redireccionRedSolicial}
                  target="_blank"
                  onClick={() => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: "ga_event",
                      category: "Footer",
                      action: "Redes sociales",
                      label: social.iconoRedSocial.description,
                      user_type: userType(),
                      value: "",
                    });
                  }}
                >
                  {social.fluid ? (
                    <Img
                      loading="lazy"
                      itemProp="image"
                      className="fluid-image"
                      fluid={social.fluid}
                      alt={social.description}
                    />
                  ) : (
                    <img
                      src={social.iconoRedSocial.file.url}
                      alt={social.description}
                    />
                  )}
                </a>
              );
            })}
          </div>

          <div className="f-footer-info">
            <a
              onClick={() => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: "ga_event",
                  category: "Footer",
                  action: "Click",
                  label: info.tituloTerminosYCondiciones,
                  user_type: userType(),
                  value: "",
                });
                goToUrl(info.redirecionTerminosYCondiciones);
              }}
            >
              {" "}
              {info.tituloTerminosYCondiciones}{" "}
            </a>

            <a onClick={() => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: "ga_event",
                      category: "Footer",
                      action: "Click",
                      label: info.tituloPoliticasDeProteccion,
                      user_type: userType(),
                      value: "",
                    });
                goToUrl(info.redireccionPoliticasDeProteccion)}
                }>
              {" "}
              {info.tituloPoliticasDeProteccion}{" "}
            </a>
          </div>
        </div>
      </footer>
    );
}

export default Footer
