import React from "react"
import Layout from "../shared/components/organisms/layout/layout"
import '../shared/styles/index.scss';
import SEO from "../shared/components/atoms/seo/seo";
import {ContentDataPolicy} from "../components/organisms/content-data-policy/content-data-policy";
import {graphql} from "gatsby"

const IndexPage = (props) => {
    const {allContentfulFamiliaInstitucionalPreguntasFrecuentes} = props.data

    return (
    <Layout>
        <SEO
          title={allContentfulFamiliaInstitucionalPreguntasFrecuentes.nodes[0].metaTitulo}
          description={allContentfulFamiliaInstitucionalPreguntasFrecuentes.nodes[0].metaDescripcion.metaDescripcion}
        />


      <ContentDataPolicy/>
    </Layout>
  )
}
export const query = graphql`
    query Meta_Query_protection {
        allContentfulFamiliaInstitucionalPreguntasFrecuentes(filter: {slug: {eq: "/politicas-de-proteccion-a-la-informacion"}}) {
            nodes {
                metaTitulo
                metaDescripcion{
                    metaDescripcion
                }
            }
        }
    }
`


export default IndexPage
