import React from 'react'
import './page-not-found.scss'
import Button from "../../../shared/components/atoms/button/button";
import siteUrl from "../../../shared/utils/siteUrl";
import {useStaticQuery, graphql} from "gatsby"
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import Img from "gatsby-image";
import useMobile from "../../../shared/hooks/useMobile";


const PageNotFound = () => {
  const isMobile = useMobile()

  const {allContentfulFamiliaInstitucionalPagina404} = useStaticQuery(
    graphql`
        query Not_Found_Query {
            allContentfulFamiliaInstitucionalPagina404 {
                nodes {
                    descripcionPrimaria {
                        raw
                    }
                    descripcionSecundaria {
                        raw
                    }
                    imagenLateral {
                        description
                        fluid {
                            aspectRatio
                            base64
                            sizes
                            src
                            srcSet
                        }
                    }
                }
            }
        }


    `
  )

  const contentInfo = allContentfulFamiliaInstitucionalPagina404.nodes[0];

  return (
    <section className="f-page-not-found">
      <div className="f-page-not-found__wrapper">
        <div className="f-page-not-found__img">

            <Img
              className="image-fluid"
              loading="lazy"
              itemProp="image"
              fluid={contentInfo.imagenLateral[isMobile ? 1 : 0]?.fluid}
              alt={contentInfo?.imagenLateral[isMobile ? 1 : 0]?.description}
            />

        </div>
        <div className="f-page-not-found__description">
          {documentToReactComponents(JSON.parse(contentInfo.descripcionPrimaria.raw))}
          {documentToReactComponents(JSON.parse(contentInfo.descripcionSecundaria.raw))}

          <div className="f-page-not-found__buttons">
            <Button text="Ir al inicio" action={() => {
              window.open(siteUrl, '_self')
            }}/>
            <Button text="Contactanos" action={() => {

              window.open(siteUrl + '/centro-de-ayuda/contactanos/', '_self')
            }}/>
          </div>

        </div>

      </div>
    </section>
  )
}

export default PageNotFound
