import React from 'react'
import './content-data-policy.scss'
import Banner from '../../../shared/components/atoms/banner/banner'
import { useStaticQuery, graphql } from 'gatsby'
import { ContentListPages } from '../../../shared/components/molecules/content-list-pages/content-list-pages';

export const ContentDataPolicy = () => {
  const { allContentfulFamiliaInstitucionalPreguntasFrecuentes } = useStaticQuery(
    graphql`
          query DataPolicyQuery {
            allContentfulFamiliaInstitucionalPreguntasFrecuentes(filter: {slug: {eq: "/politicas-de-proteccion-a-la-informacion"}}) {
              nodes {
                preguntas {
                  ... on ContentfulFamiliaInstitucionalPreguntas {
                    tituloPregunta
                    pregunta {
                      raw
                    }
                  }
                }
                titulo
                tituloBoton
                urlVideo
                descripcionVideo
                colorDeFondoVideo
                banner {
                  fluid {
                    aspectRatio
                    base64
                    sizes
                    src
                    srcSet
                  }
                  file {
                    url
                    contentType
                  }
                  description
                }
              }
            }
          }
        `
  )

  const info = allContentfulFamiliaInstitucionalPreguntasFrecuentes.nodes[0]

  const dataVideo = {
    url: info.urlVideo,
    txt: info.descripcionVideo,
    color: info.colorDeFondoVideo
  }

  return (
    <>
      <Banner image={info.banner} video={dataVideo} />

      <section className="f-terms-conditions">
        {/* <div className="f-terms-conditions-title"> {info.titulo} </div> */}
        <ContentListPages info={info} isLoadMore={false} />
      </section>
    </>
  )
}
