// Cloudfront
export const SERVER_URL = process.env.ENVIRONMENT === "production" ?
     "https://2blh4fqkq6.execute-api.us-east-1.amazonaws.com/pdn/institucional" :
        "https://u79iu4uev0.execute-api.us-east-1.amazonaws.com/dev/institucional";

export const CLIENT_ID = process.env.ENVIRONMENT !== 'production' ? '7r0v99o5el9snv8skapa8ioqjg' : '58g22292g5md9m5fheutlohg0r'

export const REFRESH_TOKEN = process.env.ENVIRONMENT !== 'production' ? 
    'https://institucional-pdn.auth.us-east-1.amazoncognito.com' :
        'https://institucional-dev.auth.us-east-1.amazoncognito.com'

// authentication
export const authentication = SERVER_URL + '/authentication/api/v1/auth'

// recovery
export const createTemporaryPassword = SERVER_URL + '/recovery/api/v1/create-temporary-password'
export const changePassword = SERVER_URL + '/recovery/api/v1/change-password'

// Get ip Address
export const GET_IP_ADDRESS = 'https://jsonip.com/';

//account
export const accountInfo = SERVER_URL + "/account/api/v1/user";

// register
export const register = SERVER_URL + "/register/api/v1/register";

//Like
export const likeArticle = SERVER_URL + '/rating/api/v1/rate'
export const likesForArticle = SERVER_URL + '/rating/api/v1/rating'
export const LikesUser = SERVER_URL + '/rating/api/v1/ratings'

//DOwnload
export const DownloadArticle = SERVER_URL + "/content/api/v1/download"
export const GetDownloads = SERVER_URL + "/content/api/v1/downloaded"

