export const getNewArticles = data => {
  const newData = data.slice(0, 10)
  const newArticles = [];

  const articlesPriority = newData.filter((item, index) => {
    return item.prioridadCard === true
  });

  const articles = newData.filter((item, index) => {
    return item.prioridadCard === false
  });

  if (articlesPriority.length > 3) {
    //Si hay mas de 3 prioritarios tomar al azar entre estos
    for (let i = 0; i < 3; i++) {
      const numRandom = Math.floor(Math.random() * articlesPriority.length)

      newArticles.push(articlesPriority[numRandom]);
      articlesPriority.splice(numRandom, 1);
    }
  } else {
    //Se calcula cuantos prioritarios hay y cuantos no prioritarios se deben mostrar
    let numberPriority = articlesPriority.length;
    let numberArticles = 3 - numberPriority;

    //No prioritarios
    for (let i = 0; i < numberArticles; i++) {
      const numRandom = Math.floor(Math.random() * articles.length)

      newArticles.push(articles[numRandom]);
      articles.splice(numRandom, 1);
    }
    //Prioritarios
    for (let i = 0; i < numberPriority; i++) {
      const numRandom = Math.floor(Math.random() * articlesPriority.length)

      newArticles.push(articlesPriority[numRandom]);
      articlesPriority.splice(numRandom, 1);
    }
  }


  return newArticles
}
