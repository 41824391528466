import React, {useState} from "react"
import Layout from "../shared/components/organisms/layout/layout"
import '../shared/styles/index.scss';
import ConTentHome from "../components/organisms/content-home/content-home";
import SEO from "../shared/components/atoms/seo/seo";
import {graphql} from "gatsby"

const IndexPage = (props) => {
  const [showModal, setShowModal] = useState(false)
  const {allContentfulFamiliaInstitucionalHome} = props.data

  return (
    <Layout onSetModal={setShowModal}>

      <SEO
        title={allContentfulFamiliaInstitucionalHome.nodes[0].metaTitulo}
        description={allContentfulFamiliaInstitucionalHome.nodes[0].metaDescripcion.metaDescripcion}
      />

      <ConTentHome isModal={showModal}/>
    </Layout>
  )
}


export const query = graphql`
    query Meta_Query {
        allContentfulFamiliaInstitucionalHome {
            nodes {
                metaTitulo
                metaDescripcion{
                    metaDescripcion
                }
            }
        }
    }
`

export default IndexPage
