import * as endpoints from "./../constants/api-constants";
import { axiosInstance } from './axios-instance';

class LikeService {

  static getLike(contentId) {
    return axiosInstance.get(endpoints.likesForArticle + '?contentId='+contentId).then(resp => resp.data)
  }

  static setLike(payload, config) {
    return axiosInstance.post(endpoints.likeArticle, payload, config).then(resp => resp.data)

  }

  static getLikesUser(config){
    return axiosInstance.get(endpoints.LikesUser, config).then(res => res.data)
  }
}

export default LikeService;
