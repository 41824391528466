import React from "react";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import "./section-banner.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowSlider from "../../../shared/components/atoms/arrow-slider/arrow-slider";
import { useStaticQuery, graphql, navigate } from "gatsby";
import useMobile from "../../../shared/hooks/useMobile";
import { userType } from "../../../shared/utils/getUserType";

const SectionBanner = () => {
  const isMobile = useMobile();

  const { allContentfulFamiliaInstitucionalHome } = useStaticQuery(
    graphql`
      query BannerQuery {
        allContentfulFamiliaInstitucionalHome {
          nodes {
            banner {
              urlVideo
              redireccionBanner
              descripcionVideo
              colorDeFondoVideo
              bannerDesktop {
                file {
                  url
                  contentType
                }
                fluid {
                  base64
                  aspectRatio
                  sizes
                  srcSet
                  src
                }
                description
              }
              bannerMobile {
                file {
                  url
                  contentType
                }
                fluid {
                  base64
                  aspectRatio
                  sizes
                  srcSet
                  src
                }
                description
              }
            }
          }
        }
      }
    `
  );

  const contentInfo = allContentfulFamiliaInstitucionalHome.nodes[0].banner;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowSlider direction="right" />,
    prevArrow: <ArrowSlider direction="left" />,
    swipe: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          swipe: true,
        },
      },
    ],
  };
  const redirection = (url) => {
    if (url.includes("http") || url.includes("www")) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  return (
    <section className="f-section-banner">
      <Slider className="f-section-banner__slider" {...settings}>
        {contentInfo &&
          contentInfo.map((data, index) => {
            if (data.bannerDesktop) {
              return (
                <div
                  key={index}
                  className={`f-section-banner__img-container ${
                    data.redireccionBanner && "redirection"
                  }`}
                  onClick={() => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: "ga_event",
                      category: "Home",
                      action: "Click - Banner",
                      label: isMobile
                        ? data.bannerMobile.description
                        : data.bannerDesktop.description,
                      user_type: userType(),
                      value: "",
                    });

                    data.redireccionBanner &&
                      redirection(data.redireccionBanner);
                  }}
                >
                  <img
                    src={
                      isMobile
                        ? data.bannerMobile.file.url
                        : data.bannerDesktop.file.url
                    }
                    alt={
                      isMobile
                        ? data.bannerMobile.description
                        : data.bannerDesktop.description
                    }
                  />
                </div>
              );
            } else if (data.urlVideo) {
              return (
                <div
                  key={index}
                  className={`f-section-banner__video-container ${data.colorDeFondoVideo}`}
                >
                  <h2 className="f-section-banner__video-txt">
                    {data.descripcionVideo}
                  </h2>
                  {
                    data.urlVideo.includes("jwplatform") ? (
                      <video
                        className="f-section-banner__video"
                        src={data.urlVideo}
                        controls
                        onPlay={() => {
                          window.dataLayer = window.dataLayer || [];
                          window.dataLayer.push({
                            event: "ga_event",
                            category: window.location.pathname,
                            action: "Video - Play",
                            label: data.descripcionVideo,
                            value: "",
                          });
                        }}
                        onPauseCapture={() => {
                          window.dataLayer = window.dataLayer || [];
                          window.dataLayer.push({
                            event: "ga_event",
                            category: window.location.pathname,
                            action: "Video - Pause",
                            label: data.descripcionVideo,
                            value: "",
                          });
                        }}
                        onEndedCapture={() => {
                          window.dataLayer = window.dataLayer || [];
                          window.dataLayer.push({
                            event: "ga_event",
                            category: window.location.pathname,
                            action: "Video - Complete",
                            label: data.descripcionVideo,
                            value: "",
                          });
                        }}
                      />
                    ) : data.urlVideo.includes("youtube") ? (
                      <ReactPlayer
                        className="f-section-banner__video"
                        width="484px"
                        height="65%"
                        url={data.urlVideo}
                        controls
                        onPlay={() => {
                          window.dataLayer = window.dataLayer || [];
                          window.dataLayer.push({
                            event: "ga_event",
                            category: window.location.pathname,
                            action: "Video - Play",
                            label: data.descripcionVideo,
                            value: "",
                          });
                        }}
                        onPause={() => {
                          window.dataLayer = window.dataLayer || [];
                          window.dataLayer.push({
                            event: "ga_event",
                            category: window.location.pathname,
                            action: "Video - Pause",
                            label: data.descripcionVideo,
                            value: "",
                          });
                        }}
                        onEnded={() => {
                          window.dataLayer = window.dataLayer || [];
                          window.dataLayer.push({
                            event: "ga_event",
                            category: window.location.pathname,
                            action: "Video - Complete",
                            label: data.descripcionVideo,
                            value: "",
                          });
                        }}
                      />
                    ) : (
                      <iframe
                        frameBorder="0"
                        scrolling="no"
                        src={data.urlVideo}
                        sandbox="allow-popups allow-same-origin allow-scripts"
                        className="f-section-banner__video"
                        allowFullScreen
                      />
                    )
                  }
                </div>
              );
            }
          })}
      </Slider>
    </section>
  );
};

export default SectionBanner;
