import React, { useState, useEffect } from "react"
import { Calendar } from "react-modern-calendar-datepicker"
import { useStaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import CalendarCustom from "../../../utils/custom-calendar"
import optionsTextEnriched from "../../../shared/utils/optionsTextEnriched"
import Button from "./../../../shared/components/atoms/button/button"

import "./section-calendar.scss"
import "react-modern-calendar-datepicker/lib/DatePicker.css"
import { createDate, getDateToObject } from "../../../shared/utils/dateFormat"
import { userType } from "../../../shared/utils/getUserType"

const SectionCalendar = () => {
  const { allContentfulFamiliaInstitucionalFechasImportantes } = useStaticQuery(
    graphql`
      query CalendarQuery {
        allContentfulFamiliaInstitucionalFechasImportantes {
          nodes {
            id
            tituloDefault
            titulo
            eventosImportantes {
              tituloEvento
              tituloBoton
              titulo
              redireccionBoton
              imagenEvento {
                file {
                  url
                }
                description
              }
              fechaEvento(formatString: "YYYY, M, D")
              descripcionEvento {
                raw
              }
            }
            descripcionDefault {
              raw
            }
            imagenDefault {
              file {
                url
              }
              description
            }
          }
        }
      }
    `
  )

  const info = allContentfulFamiliaInstitucionalFechasImportantes.nodes[0]

  const [selectedDays, setSelectedDays] = useState()
  const [infoCalendar, setinfoCalendar] = useState()
  const [customDay, setCustomDay] = useState()
  const [defaultDay, setDefaultDay] = useState()

  useEffect(() => {
    let eventsDates = []

    const currentDay = createDate()
    let foundMonthEvent = false

    info.eventosImportantes.map((event) => {
      const eventDate = createDate(event.fechaEvento)

      if (isAMothEvent(eventDate, currentDay) && foundMonthEvent === false) {
        setinfoCalendar(event)
        handleSetCustom(event)
        eventsDates.unshift(getDateToObject(eventDate))
        foundMonthEvent = true
      } else {
        const eventDateObject = getDateToObject(eventDate)
        eventsDates.push(eventDateObject)
      }
    })
    handleSetCustom({fechaEvento: currentDay})

		//set default day when no month events found
    if (!foundMonthEvent) {
      setinfoCalendar({
        tituloEvento: info.tituloDefault,
        imagenEvento: info.imagenDefault,
        descripcionEvento: info.descripcionDefault,
      })
      info.eventosImportantes.push(currentDateEvent)
      eventsDates.unshift(getDateEvent(currentDay))
    }

    setSelectedDays(eventsDates)
  }, [])

  const isAMothEvent = (eventDate, currentMonth = new Date()) => {
    return (
      eventDate.getMonth() === currentMonth.getMonth() &&
      eventDate.getFullYear() === currentMonth.getFullYear() &&
      eventDate.getDate() > currentMonth.getDate()
    )
  }



// Obtener la fecha actual

// Comparar si la fecha es hoy

  const handleSetCustom = (data) => {
  const fechaObjetivo = new Date(data.fechaEvento);
  
  const fechaActual = new Date();
  let className = "__day-active"

  if (
    fechaObjetivo.getDate() === fechaActual.getDate() &&
    fechaObjetivo.getMonth() === fechaActual.getMonth() &&
    fechaObjetivo.getFullYear() === fechaActual.getFullYear()
  ) {
    className = "__day-active-today"
  } 
      let nDEI = createDate()
    if (data) {
      nDEI = createDate(data.fechaEvento)
    }

    const dEI = getDateEvent(createDate(nDEI))
    dEI.className = className

    setCustomDay([dEI])
		!data && setDefaultDay(dEI)
  }

  const getDateEvent = (d, day) => {
    return {
      day: day === 0 ? day : d.getDate(),
      month: d.getMonth() + 1,
      year: d.getFullYear(),
    }
  }

  const currentDate = getDateEvent(createDate())
  const currentDateEvent = {
    descripcionEvento: info.descripcionDefault,
    fechaEvento: `${currentDate.year}, ${currentDate.month}, ${currentDate.day}`,
    imagenEvento: info.imagenDefault,
    tituloEvento: info.tituloDefault,
  }

  const handleInfo = (currentDates) => {
    let isData = false
    let isEventDay = false

    info.eventosImportantes.map((eI) => {
      isData = false

      const nDEI = createDate(eI.fechaEvento)
      const dEI = getDateEvent(createDate(nDEI))

      currentDates.map((cDate) => {
        isData = JSON.stringify(dEI) === JSON.stringify(cDate) ? true : isData
      })

      if (isData === false) {
        isEventDay = true
        setinfoCalendar(eI)
        handleDataLayerDate(eI)
        dEI.className = "__day-active-today"
    }
    })
    if(!isEventDay) {
      setinfoCalendar(currentDateEvent)
    }
  }

  const handleDataLayerDate = (info) => {
    info && (window.dataLayer = window.dataLayer || [])
    window.dataLayer.push({
      event: "ga_event",
      category: "Home",
      action: "Click - Fechas importantes",
      label: info?.fechaEvento,
      user_type: userType(),
      value: "",
    })
  }
  return (
    <section className='f-calendar'>
      <div className='f-calendar-title'> Fechas importantes </div>
      <div className='f-calendar-cont'>
        <Calendar
          value={selectedDays}
          onChange={handleInfo}
          locale={CalendarCustom}
          customDaysClassName={customDay}
        />

        {infoCalendar && (
          <>
            <div className='f-calendar-cont-image'>
              <img
                src={infoCalendar.imagenEvento.file.url}
                alt={infoCalendar.imagenEvento.description}
              />
            </div>

            <div className='f-calendar-cont-info'>
              <div className='f-calendar-cont-info-title'>
                {infoCalendar.tituloEvento}
              </div>
              <div className='f-calendar-cont-info-description'>
                {documentToReactComponents(
                  JSON.parse(infoCalendar.descripcionEvento.raw),
                  optionsTextEnriched
                )}
              </div>

              {infoCalendar.tituloBoton && infoCalendar.redireccionBoton && (
                <div className='f-calendar-cont-info-button'>
                  <Button
                    text={infoCalendar.tituloBoton}
                    url={infoCalendar.redireccionBoton}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default SectionCalendar
