import React from "react"
import Layout from "../shared/components/organisms/layout/layout"
import '../shared/styles/index.scss';
import SEO from "../shared/components/atoms/seo/seo";
import ContentResultsSearch from "../components/organisms/content-results-search/content-results-search";

const IndexPage = () => (
  <Layout>
    <SEO
      title="Busqueda"
      description="Resultados busqueda"
    />

    <ContentResultsSearch />
  </Layout>
)

export default IndexPage
