import React from "react";
import './loading.scss'
import imgLoading from './../../../assets/images/loading.gif'

const Loading = () => {
  return (
    <div className="f-loading">
      <img src={imgLoading} alt="Cargando" />
    </div>
  )

}

export default Loading
