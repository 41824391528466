import React from 'react'
import './item-list-pages.scss'
import optionsTextEnriched from "./../../../utils/optionsTextEnriched"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { userType } from '../../../utils/getUserType';

import chevron from "./../../../assets/icons/icon-chevron-left.svg"

export const ItemListPages = ({ data, onActive, index, isActive }) => {
    const onHandleActive = (index) => {
        onActive(index)
    }

    return (
        <div key={index} className={isActive == index ? 'f-list-pages-container active' : 'f-list-pages-container'}>
            <div className="f-list-pages-container-header">
                <div className="f-list-pages-container-header-title"> {data.tituloPregunta} </div>
                <div 
                  className="f-list-pages-container-header-chevron" 
                  onClick={() => {
                    onHandleActive(index);
                    (window.dataLayer = window.dataLayer || []);
                    window.dataLayer.push({
                      event: "ga_event",
                      category: "Centro de ayuda",
                      action: "Click - Preguntas frecuentes",
                      label: data.tituloPregunta,
                      user_type: userType(),
                      value: "",
                    });
                  }
                 }>
                    <img src={chevron} alt={chevron} />
                </div>
            </div>

            <div className="f-list-pages-container-body">
                {documentToReactComponents(JSON.parse(data.pregunta.raw), optionsTextEnriched)}
            </div>
        </div>
    )
}
