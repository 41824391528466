import React, {useEffect} from "react";
import './modal.scss'

const Modal = ({showModal, setShowModal, children, wrapperClass}) => {

  useEffect(() => {
    handleOverflowBody('open')
  }, [])


  const handleOverflowBody = (action) => {
    const body = document.getElementsByTagName("body")[0];
    if (action === 'open') {
      body.classList.add("overflow-body-hidden");
    } else if (action === 'close') {
      body.classList.remove("overflow-body-hidden");
    }
  }

  return (
    <div className={`f-modal ${wrapperClass || ''}`}>
      <div className="f-modal__content">
      <span className="f-modal__close" onClick={() => {
        handleOverflowBody('close')
        setShowModal(false)
      }}><i className="fal fa-times"></i></span>
        {children}
      </div>
    </div>
  )

}

export default Modal
