import React from "react"
import Layout from "../shared/components/organisms/layout/layout"
import '../shared/styles/index.scss';
import SEO from "../shared/components/atoms/seo/seo";
import {ContentTermsConditions} from "../components/organisms/content-terms-conditions/content-terms-conditions";
import {graphql} from "gatsby"

const IndexPage = (props) => {
  const {allContentfulFamiliaInstitucionalPreguntasFrecuentes} = props.data

  return (
    <Layout>
      <SEO
        title={allContentfulFamiliaInstitucionalPreguntasFrecuentes.nodes[0].metaTitulo}
        description={allContentfulFamiliaInstitucionalPreguntasFrecuentes.nodes[0].metaDescripcion.metaDescripcion}
      />


      <ContentTermsConditions/>
    </Layout>
  )
}

export const query = graphql`
    query Meta_Query_terms {
        allContentfulFamiliaInstitucionalPreguntasFrecuentes(filter: {slug: {eq: "terminos-y-condiciones/"}}) {
            nodes {
                metaTitulo
                metaDescripcion{
                    metaDescripcion
                }
            }
        }
    }
`

export default IndexPage
