import React from "react"
import "./arrow-slider.scss"
import { userType } from "../../../utils/getUserType"

const ArrowSlider = props => {
  const { direction, banner, onClick } = props
  return (
    <button
      className={`${banner ? `f-btn-slider-banner ${direction}` : `f-btn-slider ${direction}`}`}
      onClick={ () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event':'ga_event',
          'category':'Home',
          'action':'Click - Banner flechas',
          'label': direction,
          user_type: userType(),
          'value':''
        });
        onClick()
      }
        
        }>
      <div>
        <i className={'fas fa-chevron-' + direction}></i>
      </div>
    </button>)
}

export default ArrowSlider
