import React, { useState, useEffect } from 'react'
import './content-search.scss'
import iconSearch from './../../../assets/icons/icon-search.svg'
import { useStaticQuery, graphql } from 'gatsby'
import siteUrl from '../../../utils/siteUrl'

export const ContentSearch = ({ label, labelResults }) => {
    const [articlesResults, setArticlesResults] = useState([])
    const [pagesResults, setPagesResults] = useState([])
    const [isResults, setIsResults] = useState('')
    const [valueInput, setValueInput] = useState('')

    const {
        allContentfulFamiliaInstitucionalArticulosCards,
        allContentfulFamiliaInstitucionalHeader,
        allContentfulFamiliaInstitucionalFooter
    } = useStaticQuery(
        graphql`
            query SearchQuery {
                allContentfulFamiliaInstitucionalArticulosCards(sort: {order: DESC, fields: fechaDePublicacionCard}, filter: {tipoDeArticulo: {ne: "Capsula"}, Slug: { ne: "contenido-oculto"}}) {
                    nodes {
                        id
                        contentful_id
                        tituloCard
                        Slug
                        tipoDeContenido {
                            nombreCategoria
                        }
                        redireccionExterna
                        descripcionCard {
                            raw
                        }
                        fechaDePublicacionCard
                        tags {
                            titulo
                            nombreTag
                        }
                        imagenCard {
                            fluid {
                                aspectRatio
                                base64
                                sizes
                                src
                                srcSet
                            }
                            description
                        }
                        prioridadCard
                        categoriaCard {
                            nombreCategoria
                        }
                        descripcionArticulo {
                            raw
                        }
                    }
                }
                allContentfulFamiliaInstitucionalHeader {
                    nodes {
                        redireccionAyuda
                        redireccionLoNuevo
                        tituloAyuda
                        tituloLoNuevo
                        botonRedireccionExterna{
                            tituloBotonHeader
                            redireccionBotonHeader
                            pais
                        }
                        opcionesHeader {
                            tituloOpcion
                            redireccionOpcion
                            submenuOpcion {
                                tituloOpcion
                                redireccionOpcion
                            }
                        }
                    }
                }
                allContentfulFamiliaInstitucionalFooter {
                    nodes {
                        tituloPoliticasDeProteccion
                        tituloTerminosYCondiciones
                        redireccionPoliticasDeProteccion
                        redirecionTerminosYCondiciones
                        lineaTelefonica {
                            nombreLineaTelefonica
                            numeroLineaTelefonica
                            pais
                        }
                        redesSociales {
                            redireccionRedSolicial
                            titulo
                        }
                    }
                }
            }
        `
    )

    const info = allContentfulFamiliaInstitucionalArticulosCards.nodes
    const infoHeader = allContentfulFamiliaInstitucionalHeader.nodes[0]
    const infoFooter = allContentfulFamiliaInstitucionalFooter.nodes[0]

    const onSubmit = (e) => {
        if (e.keyCode === 13 && valueInput.length > 0) {
            getResults(e.target.value, true)

            window.location.href = siteUrl + '/resultados-de-busqueda'
        }
    }

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    const handleResult = (title, url, input, blank) => {
        const tA = removeAccents(title).toUpperCase()

        const uri = url !== false ?
            blank ? url : siteUrl + url
            : false

        return tA.indexOf(input) >= 0 && { title, url: uri, blank }
    }

    const getResults = (input, hidenResults) => {
        setValueInput(input)

        if (input?.length > 0) {
            input = removeAccents(input).toUpperCase()

            const results = []

            info.map(item => {
                const tt = removeAccents(item.tituloCard).toUpperCase()
                if (tt.indexOf(input) >= 0) {
                    results.push(item)
                } else {
                    const res = item?.tags?.filter((tag) => {
                        const nT = removeAccents(tag.nombreTag).toUpperCase()
                        return nT.indexOf(input) >= 0
                    })

                    if (res?.length) {
                        results.push(item)
                    } else if (item?.descripcionArticulo) {
                        let desc = JSON.stringify(item.descripcionArticulo.raw)
                        desc = removeAccents(desc).toUpperCase()
                        desc.indexOf(input) >= 0 && results.push(item)
                    }
                }
            })

            const pResults = []

            const rA = handleResult(infoHeader?.tituloAyuda, infoHeader?.redireccionAyuda, input)
            rA && pResults.push(rA)

            const rLN = handleResult(infoHeader?.tituloLoNuevo, infoHeader?.redireccionLoNuevo, input)
            rLN && pResults.push(rLN)

            if (localStorage.getItem('USER_INFO')) {
                const linkIns = infoHeader?.botonRedireccionExterna.filter(link => link.pais === JSON.parse(localStorage.getItem('USER_INFO'))?.country)[0]

                if (linkIns) {
                    const rC = handleResult(linkIns?.tituloBotonHeader, linkIns?.redireccionBotonHeader, input, true)
                    rC && pResults.push(rC)
                }
            }

            infoHeader.opcionesHeader.map(option => {
                const rO = handleResult(option.tituloOpcion, option.redireccionOpcion, input)
                rO && pResults.push(rO)

                if (option.submenuOpcion) {
                    option.submenuOpcion.map(submenu => {
                        const rOS = handleResult(submenu.tituloOpcion, submenu.redireccionOpcion, input)
                        rOS && pResults.push(rOS)
                    })
                }
            })

            const rPP = handleResult(infoFooter?.tituloPoliticasDeProteccion, infoFooter?.redireccionPoliticasDeProteccion, input)
            rPP && pResults.push(rPP)

            const rTC = handleResult(infoFooter?.tituloTerminosYCondiciones, infoFooter?.redirecionTerminosYCondiciones, input)
            rTC && pResults.push(rTC)

            if (localStorage.getItem('USER_INFO')) {
                const line = infoFooter?.lineaTelefonica.filter(line => line.pais === JSON.parse(localStorage.getItem('USER_INFO')).country)[0]

                if (line) {
                    const rC = handleResult(`${line.nombreLineaTelefonica} ${line.numeroLineaTelefonica}`, false, input)
                    rC && pResults.push(rC)
                }
            }

            infoFooter.redesSociales.map(social => {
                const rR = handleResult(social.titulo, social.redireccionRedSolicial, input, true)
                rR && pResults.push(rR)
            })

            if (!hidenResults) {
                setIsResults('active')
            }

            results.sort(function (a, b) {
                if (a?.tituloCard > b?.tituloCard) {
                    return 1;
                }
                if (a?.tituloCard < b?.tituloCard) {
                    return -1;
                }
                return 0;
            });

            setArticlesResults(results)

            pResults.sort(function (a, b) {
                if (a.title > b.title) {
                    return 1;
                }
                if (a.title < b.title) {
                    return -1;
                }
                return 0;
            });

            setPagesResults(pResults)

            const res = {
                search: valueInput,
                results: results
            }

            localStorage.setItem('SEARCH_RESULTS', JSON.stringify(res))
        }
        else {
            setIsResults('')
            setArticlesResults([])
            setPagesResults([])
        }
    }

    const goToLink = (item, section) => {
        if (section) {
            if (item.url) {
                item.blank ? window.open(item.url) : window.location.href = item.url
            }
        } else {
            const category = item.categoriaCard.nombreCategoria
            const preSlug = (category === 'Transversal') ? '/lo-nuevo/' : '/territorios/'

            window.location.href = siteUrl + preSlug + item.Slug
        }
    }

    const handleClean = () => {
        localStorage.removeItem('SEARCH_RESULTS')
        setValueInput('')
        setIsResults('')
    }

    useEffect(() => {
        if (localStorage.getItem('SEARCH_RESULTS')) {
            setValueInput(JSON.parse(localStorage.getItem('SEARCH_RESULTS')).search)
        }
    }, [])

    return (
        <div className="f-content-input-i f-search">
            <div className={`f-search-results ${isResults}`}  >


                {pagesResults && <> Secciones </>}

                {
                    pagesResults && pagesResults.map((item, index) => {
                        return (
                            <div key={index}>
                                {index < 5 &&
                                    <span onClick={() => goToLink(item, true)}> {item.title} </span>
                                }
                            </div>
                        )
                    })
                }

                {!pagesResults.length &&
                    <span className="f-search-results-not"> {labelResults} </span>
                }

                {articlesResults && <> Artículos </>}

                {
                    articlesResults && articlesResults.map((item, index) => {
                        return (
                            <div key={index}>
                                {index < 5 &&
                                    <span onClick={() => goToLink(item)}> {item.tituloCard} </span>
                                }
                            </div>
                        )
                    })
                }

                {!articlesResults.length &&
                    <span className="f-search-results-not"> {labelResults} </span>
                }
            </div>

            <div className="f-search-dialog" >
                <img className="f-content-input-i-search" src={iconSearch} alt="Buscador" />
                <input autoFocus placeholder={label} value={valueInput} onKeyDown={e => onSubmit(e)} onChange={e => getResults(e.target.value)} type="text" />

                {valueInput != '' &&
                    <i className="f-content-input-i-close fas fa-times-circle" onClick={() => handleClean()}></i>
                }
            </div>
        </div>
    )
}
