import React, { useState, useEffect } from 'react'
import siteUrl from '../../../utils/siteUrl'
import { useStaticQuery, graphql } from 'gatsby'
import { userType } from '../../../utils/getUserType'

import './header.scss'
import logo from '../../../assets/images/logo.png'
import menu from '../../../assets/icons/icon-menu.svg'
import search from '../../../assets/icons/icon-search.svg'

import { ContentSearch } from '../../molecules/content-search/content-search'
import useMobile from '../../../hooks/useMobile'

const Header = ({ userName }) => {
  const { allContentfulFamiliaInstitucionalHeader } = useStaticQuery(
    graphql`
        query HeaderQuery {
            allContentfulFamiliaInstitucionalHeader {
                nodes {
                    id
                    redireccionAyuda
                    redireccionLoNuevo
                    saludoUsuario
                    titulo
                    tituloAyuda
                    tituloBuscador
                    tituloBuscadorSinResultados
                    tituloLoNuevo
                    botonRedireccionExterna{
                        tituloBotonHeader
                        redireccionBotonHeader
                        pais
                    }
                    opcionesHeader {
                        tituloOpcion
                        redireccionOpcion
                        submenuOpcion {
                            tituloOpcion
                            imagenOpcion {
                                file {
                                    url
                                }
                                description
                            }
                            titulo
                            redireccionOpcion
                        }
                    }
                }
            }
        }
    `
  )

  const info = allContentfulFamiliaInstitucionalHeader.nodes[0]

  const [isActiveLink, setisActiveLink] = useState()
  const [isActiveUser, setisActiveUser] = useState(false)
  const [activeMenu, setActiveMenu] = useState(false)
  const [path, setPhat] = useState()

  const [externalLink, setExternalLink] = useState()

  const isMobile = useMobile()

  const goToLink = (redireccionOpcion, submenuOpcion) => {
    localStorage.removeItem('INFO_CUSTOMIZER')
    if (submenuOpcion) {
      if (window.screen.width >= 1024) {
        window.location.href = siteUrl + redireccionOpcion
      } else {
        if (redireccionOpcion === isActiveLink) {
          setisActiveLink('')
        } else {
          setisActiveLink(redireccionOpcion)
        }
      }
    } else {
      window.location.href = siteUrl + redireccionOpcion
    }
  }

  //Formatear texto quitar acentos y ponerlo en minusculas
  const formatText = (text) => {
    return text.toString()?.normalize("NFD")?.replace(/[^a-zA-Z 0-9.]+/g, "").toLowerCase().trim()
  }

  useEffect(() => {
    const splitPath = window.location.pathname.split('/')
    const path = splitPath.filter(splitPath => splitPath != "")
    const country = formatText(JSON.parse(localStorage.getItem('USER_INFO'))?.country || 'COLOMBIA');

    //Filtro para slecionarr redirecion
    let redirection = info.botonRedireccionExterna.find(item => {
      return formatText(item.pais) === country
    })

    if (!redirection) {
      redirection = info.botonRedireccionExterna.find(item => {
        return formatText(item.pais) === formatText('COLOMBIA')
      })
    }

    setExternalLink(redirection)

    if (info.redireccionLoNuevo.indexOf(path[0]) >= 0) {
      setisActiveLink(info.redireccionLoNuevo)
      setPhat(info.redireccionLoNuevo)
    } else if (info.redireccionAyuda.indexOf(path[0]) >= 0) {
      setisActiveLink(info.redireccionAyuda)
      setPhat(info.redireccionAyuda)
    } else if (path.length) {
      info.opcionesHeader.map((item, index) => {
        if (item.redireccionOpcion.indexOf(path[0]) >= 0) {
          setisActiveLink(item.redireccionOpcion)
          setPhat(item.redireccionOpcion)
        }
      })
    } else {
      setisActiveLink('/')
      setPhat('/')
    }


  }, [])


  const handleLogOut = () => {
    localStorage.removeItem('INFO_CUSTOMIZER')
    localStorage.removeItem('TOKEN_INFO')
    localStorage.removeItem('USER_INFO')
    window.location.href = siteUrl + '/autenticacion'
  }

  const handleDataLayerOpcMenu = (tituloOpcion) =>{
      (window.dataLayer = window.dataLayer || []);
      tituloOpcion = tituloOpcion.replace('/','')
      if(tituloOpcion === ''){
        tituloOpcion= 'Inicio'
      }
      window.dataLayer.push({
        event: "ga_event",
        category: "Menú",
        action: "Click",
        label: tituloOpcion,
        user_type: userType(),
        value: "",
      })
  }
  return (
    <section className="f-header">
      <div className="f-header-container">
        <div className="f-header-logo">
          <div onClick={() => {goToLink("/"); handleDataLayerOpcMenu('Inicio')}}>
            <img src={logo} alt="Familia Institucional" />
          </div>
        </div>

        <div className="f-header-mobile">
          <div
            className="f-header-mobile-search"
            onClick={() => goToLink("/resultados-de-busqueda")}
          >
            <img src={search} alt="Buscador" />
          </div>

          <div
            onClick={() => setActiveMenu(!activeMenu)}
            className="f-header-mobile-menu"
          >
            <img src={menu} alt="Menú" />
          </div>
        </div>

        <div className={activeMenu ? "f-header-nav active" : "f-header-nav"}>
          <div className="f-header-nav-cont">
            <div className="f-header-nav-cont-mobile">
              <div className="f-header-nav-cont-mobile-title">Menú</div>

              <div
                onClick={() => setActiveMenu(!activeMenu)}
                className="f-header-mobile-menu"
              >
                <i className="fal fa-times"></i>
              </div>
            </div>

            <div className="f-header-nav-first">
              {info.opcionesHeader &&
                info.opcionesHeader.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`f-header-nav-first-link 
                                                ${
                                                  isActiveLink ===
                                                    item.redireccionOpcion &&
                                                  "active"
                                                } 
                                                ${
                                                  path ===
                                                    item.redireccionOpcion &&
                                                  "active"
                                                }`}
                      onClick={() => {
                        handleDataLayerOpcMenu(item.redireccionOpcion);
                        goToLink(item.redireccionOpcion, item.submenuOpcion);
                      }
                      }
                    >
                      {" "}
                      {item.tituloOpcion}
                      {item.submenuOpcion && (
                        <>
                          <i className="fas fa-chevron-down"></i>

                          <div className="f-header-nav-first-link-cont">
                            {item.submenuOpcion &&
                              item.submenuOpcion.map((itemSub, indexSub) => {
                                return (
                                  <a
                                    href={siteUrl + itemSub.redireccionOpcion}
                                    key={indexSub}
                                    className="f-header-nav-first-link-cont-subitem"
                                    onClick={() => {
                                      window.dataLayer = window.dataLayer || [];
                                      window.dataLayer.push({
                                        event: "ga_event",
                                        category: "Menú",
                                        action: "Click",
                                        label: itemSub.tituloOpcion,
                                        value: "",
                                      });
                                    }}
                                  >
                                    <img
                                      src={itemSub.imagenOpcion.file.url}
                                      alt={itemSub.imagenOpcion.descriotion}
                                    />
                                    {itemSub.tituloOpcion}
                                  </a>
                                );
                              })}
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
            </div>

            <div className="f-header-nav-separator"></div>

            <div className="f-header-nav-second">
              {!isMobile && (
                <ContentSearch
                  label={info.tituloBuscador}
                  labelResults={info.tituloBuscadorSinResultados}
                />
              )}

              <div
                className={`f-header-nav-second-link -nuevo ${
                  path === info.redireccionLoNuevo && "active"
                }`}
                onClick={() => {
                  handleDataLayerOpcMenu(info.tituloLoNuevo)
                  goToLink(info.redireccionLoNuevo)
                }}
              >
                {" "}
                {info.tituloLoNuevo}{" "}
              </div>

              <div
                className={`f-header-nav-second-link -ayuda ${
                  path === info.redireccionAyuda && "active"
                }`}
                onClick={() => {
                  handleDataLayerOpcMenu(info.tituloAyuda)
                  goToLink(info.redireccionAyuda)
                }}
              >
                {" "}
                {info.tituloAyuda}{" "}
              </div>

              <div
                onClick={() => setisActiveUser(!isActiveUser)}
                className={
                  isActiveUser
                    ? "f-header-nav-second-user active"
                    : "f-header-nav-second-user"
                }
              >
                {`${info.saludoUsuario} ${userName}`}{" "}
                <i className="fas fa-chevron-down"></i>
                <div className="f-header-nav-second-user-cont">
                  <p onClick={() => handleLogOut()}> Cerrar sesión </p>
                </div>
              </div>

              {externalLink?.redireccionBotonHeader && (
                <button
                  className="f-btn-link"
                  onClick={() => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: "ga_event",
                        category: "Menú",
                        action: "Click",
                        label: "Familia Institucional",
                        user_type: userType(),
                        value: "",
                      });
                    window.open(externalLink?.redireccionBotonHeader, "_blank")
                  }}
                >
                  {" "}
                  {externalLink?.tituloBotonHeader}{" "}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header
