const printParams = params => {
  console.log("===PUSH  DATALAYER")
  for (const option in params) {
    console.log(option, params[option])
  }
  console.log("===PUSH  DATALAYER")
}

const pushDataLayerEvent = params => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(params)
  if (params.event !== 'authentication' || params.event !== 'pageView') {
    setTimeout(() => {
      window.dataLayer.push(params)
    }, 1000);
  }else {
    window.dataLayer.push(params)
  }
  if (process.env.ENVIRONMENT !== "production") printParams(params)
}

const pushGtagEvent = ({ event, params }) => {
  window.gtag = window.gtag || function() {}
  window.gtag("event", event, params)
  
  console.log('=== DATALAYER EVENT: ', event)
  if (process.env.ENVIRONMENT !== "production") printParams(params)
}

export { pushDataLayerEvent, pushGtagEvent }
