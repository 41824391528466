import React from "react";
import "./banner.scss";
import Breadcrumb from "../breadcrumb/breadcrumb";
import ReactPlayer from "react-player";
import useMobile from "../../../hooks/useMobile";
import Img from "gatsby-image";
import { userType } from "../../../utils/getUserType";

const Banner = ({ image, video }) => {
  
  const isMobile = useMobile();
  
  return (
    <>
      <Breadcrumb />
      <section className="f-section-banner">
        {(video?.url && video?.url !== 'none') ? (
          <div className={`f-section-banner__video-conatiner ${video?.color}`}>
            <h2 className="f-section-banner__video-txt">{video?.txt}</h2>
            {video.url.includes("jwplatform") ? (
              <video
                className="f-section-banner__video"
                src={video?.url}
                controls
                onPlay={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: "ga_event",
                    category: window.location.pathname,
                    action: "Video - Play",
                    label: video.descripcionVideo,
                    user_type: userType(),
                    value: "",
                  });
                }}
                onPauseCapture={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: "ga_event",
                    category: window.location.pathname,
                    action: "Video - Pause",
                    label: video.descripcionVideo,
                    user_type: userType(),
                    value: "",
                  });
                }}
                onEndedCapture={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: "ga_event",
                    category: window.location.pathname,
                    action: "Video - Complete",
                    label: video.descripcionVideo,
                    user_type: userType(),
                    value: "",
                  });
                }}
              />
            ) : 
              video.url.includes("youtube")  ? ( 
              <ReactPlayer
                className="f-section-banner__video"
                width="484px"
                height="unset"
                url={video?.url}
                controls
                onPlay={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: "ga_event",
                    category: window.location.pathname,
                    action: "Video - Play",
                    label: video.descripcionVideo,
                    user_type: userType(),
                    value: "",
                  });
                }}
                onPause={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: "ga_event",
                    category: window.location.pathname,
                    action: "Video - Pause",
                    label: video.descripcionVideo,
                    user_type: userType(),
                    value: "",
                  });
                }}
                onEnded={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: "ga_event",
                    category: window.location.pathname,
                    action: "Video - Complete",
                    label: video.descripcionVideo,
                    user_type: userType(),
                    value: "",
                  });
                }}
              />
            ) : (
              <iframe
                frameBorder="0"
                scrolling="no"
                src={video?.url}
                sandbox="allow-popups allow-same-origin allow-scripts"
                className="f-section-banner__video"
                allowFullScreen
              />
            )
            }
          </div>
        ) : image[0]?.fluid && image[0]?.contentType === "image/png" ? (
          <Img
            loading="lazy"
            itemProp="image"
            className="fluid-image"
            fluid={image[isMobile ? 1 : 0].fluid}
            alt={image[isMobile ? 1 : 0].description}
          />
        ) : (
          <img
            src={image[isMobile ? 1 : 0]?.file?.url}
            alt={image[0]?.desription}
          />
        )}
      </section>
    </>
  );
};

export default Banner;
