import React, {useState, useEffect} from "react";
import './card-article.scss'
import Img from "gatsby-image"

import {documentToReactComponents} from "@contentful/rich-text-react-renderer"
import {BLOCKS} from "@contentful/rich-text-types"
import siteUrl from "../../../utils/siteUrl";
import {dateFormat} from "../../../utils/dateFormat";
import LikeService from "../../../services/like-service";
import { userType } from "../../../utils/getUserType";

const CardArticle = ({content, type, preSlug = "", section , category}) => {

  const [likes, setLikes] = useState(0);
  const [userLike, setUserLike] = useState(false);


  useEffect(() => {
    //Traer cantidad de likes
    if (content.contentful_id) {
      LikeService.getLike(content.contentful_id).then(res => {
        if (res.status) {
          setLikes(res.data)
        }
      })
    }

    //giver si el usuario le dio like a este articulo
    let likesUser = JSON.parse(localStorage.getItem("LOCAL_LIKES_ARTICLES")).some(data => {
      return data === content.contentful_id
    })

    setUserLike(likesUser)

  }, [])


  const likeArticle = (e) => {
    let tokenId = JSON.parse(localStorage.getItem("TOKEN_INFO")).idToken

    let config = {
      headers: {
        Authorization: tokenId,
      },
    }

    let payload = {
      "contentId": content.contentful_id,
      "like": !userLike
    }

    LikeService.setLike(payload, config).then(res => {

      if (res.status === 200) {
        if (!userLike) {
          setLikes(likes + 1)
        } else {
          setLikes(likes - 1)
        }
        handleLocalLikes(!userLike)
        setUserLike(!userLike)
      }

    })
  }

  const handleTags = (tags) =>{
    let tagsLayers = ''
    tags.map((data, index) => {
      tags.length-1 === index ?  tagsLayers +=  data.nombreTag : tagsLayers +=  data.nombreTag + " - " 
    })
    return(
      tagsLayers
    )
  }

  const handleLocalLikes = (status) => {
    let copyLikes = JSON.parse(localStorage.getItem("LOCAL_LIKES_ARTICLES"));

    if (status) {
      //Se dio like se agrega al local
      copyLikes.push(content.contentful_id)
      localStorage.setItem('LOCAL_LIKES_ARTICLES', JSON.stringify(copyLikes))

    } else {
      //Se le dio disLike se quita de local
      let newLikes = copyLikes.filter(data => data !== content.contentful_id)
      localStorage.setItem('LOCAL_LIKES_ARTICLES', JSON.stringify(newLikes))

    }

  }

  const options1 = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, next) => <p className={`f-card-article__paragraph ${type}`}>{next}</p>
    }
  }

  const {
    imagenCard,
    tituloCard,
    descripcionCard,
    tags,
    fechaDePublicacionCard,
    Slug,
    redireccionExterna,
    tipoDeContenido
  } = content
  let externalLink = redireccionExterna?.length > 5 && tipoDeContenido[0].nombreCategoria === 'Juegos';
  return (
    <a
      className={`f-card-article ${type}`}
      href={externalLink ? redireccionExterna : siteUrl + "/" + preSlug + Slug}
      target={externalLink ? "_blank" : "_self"}
      onClick={() => {
        (window.dataLayer = window.dataLayer || []);
          section === "recentPost"
            ? window.dataLayer.push({
                event: "ga_event",
                category: category,
                action: "Click - Publicaciones recientes",
                label: tituloCard + " - " + handleTags(tags),
                user_type: userType(),
                value: "",
              })
            : window.dataLayer.push({
                event: "ga_event",
                category: category,
                action: "Click - Artículo",
                label: tituloCard + " - " + handleTags(tags),
                user_type: userType(),
                value: "",
              });
      }}
    >
      <div className={`f-card-article__img ${type}`}>
        {imagenCard && <Img
          className="image-fluid"
          loading="lazy"
          itemProp="image"
          fluid={imagenCard?.fluid}
          alt={imagenCard?.description}
        />}
        <div className="f-card-article__like">
          <i
            className={` ${userLike ? "fas" : "fal"} fa-heart`}
            onClick={(e) => {
              likeArticle();
              e.preventDefault();
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "ga_event",
                category: category,
                action: "Click - Me gusta",
                label: tituloCard + " - " + handleTags(tags),
                user_type: userType(),
                value: "",
              });
            }}
          />
          <span>{likes}</span>
        </div>
      </div>

      <div className="f-card-article__content">
        {type === "article" && (
          <ul className="f-card-article__tags">
            {tags &&
              tags.map((data, index) => {
                if (index <= 2) {
                  return (
                    <li key={index} className="f-card-article__tags-tag">
                      {data.nombreTag}
                    </li>
                  );
                }
              })}
          </ul>
        )}
        {fechaDePublicacionCard && (
          <div className="f-card-article__date">
            <p>{dateFormat(fechaDePublicacionCard)}</p>
          </div>
        )}

        <div
          className={`f-card-article__description ${type} ${
            tags ? `` : `date`
          }`}
        >
          <h3 className="f-card-article__title">{tituloCard}</h3>
          {documentToReactComponents(JSON.parse(descripcionCard.raw), options1)}
        </div>
      </div>
    </a>
  );
}

export default CardArticle
