import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import siteUrl from "../../../utils/siteUrl";
import Footer from "../footer/footer";
import Header from "../header/header";
import { GenericModal } from "../genericModal/genericModal";
import "./layout.scss";
import Loading from "./../loading/loading";
import AuthService from "../../../services/auth-service";
import { getWindow } from "../../../utils/utilities-dom";
import { pushDataLayerEvent } from "../../../utils/metricManagementUtil";

const Layout = (props) => {
  const { children, onSetModal } = props;

  const queryTransversalModal = useStaticQuery(graphql`
    {
      allContentfulFamiliaInstitucionalModalGenerica(filter: {modalActiva: {eq: true}}) {
        nodes {
          titulo
          slugOId
          redireccionModal
          urlVideo
          imagenModal {
            description
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            file {
              url
            }
          }
        }
      }
    }  
  `);

  const GenericModalsInfo = queryTransversalModal.allContentfulFamiliaInstitucionalModalGenerica.nodes;
  const [logged, setLogged] = useState(false);
  const [modalActive, setModalActive] = useState(null);
  const [userName, setUserName] = useState("");
  const [country, setCountry] = useState("");

  const closeGenericModal = () => {
    setModalActive(null);

    if (window.location?.href?.includes("#")) {
      let newPath = window.location.href.split("#")[0];
      window.history.pushState("", "", newPath);
    }
  }

  const sendDatalayerAuth = (userData) => {
    if (!userData) return
    if(!sessionStorage.getItem("datalayerAuth")){
      let mail= userData.email?.toString().split('@')[1].split('.')[0] || ""
      pushDataLayerEvent({
        event: "authentication",
        userId: userData.userId?.toString() || "",
        userNacionalidad: userData.country?.toString(),
        userArea: userData.country?.toString(),
        userSede: userData.company?.toString(),
        userType: mail=== 'essity'? 'UsuariosEssity' : 'OtrosUsuarios'
      })
      sessionStorage.setItem("datalayerAuth", true)
    }
  }
  const sendDatalayerPageView = (userData) => {
    if (!userData) return
    if(!sessionStorage.getItem("datalayerPageView")){
      if(!localStorage.getItem("USER_INFO")){
        let location = ''
        if (typeof window !== 'undefined') location = window.location.href
        pushDataLayerEvent({
          event: "pageView",
          location: location,
          page: location.split('/').slice(3).join('/'),
          referrer: "https://tagassistant.google.com/",
          title: 'Home',
          userId: userData.userId?.toString() || ""
        })
        sessionStorage.setItem("datalayerPageView", true)
      }
    }
  }
  
  useEffect(() => {
    if (siteUrl.indexOf("localhost") < 0) {
      if (!localStorage.getItem("TOKEN_INFO")) {
        window.location.href = siteUrl + "/autenticacion";
      } else {
        const token = JSON.parse(localStorage.getItem("TOKEN_INFO")).idToken;
        if (token) {
          var tokenId = JSON.parse(localStorage.getItem("TOKEN_INFO")).idToken;

          var config = {
            headers: {
              Authorization: tokenId,
            },
          };

          AuthService.sessionInfo(config)
            .then((res) => {
              if (res.status === 200) {
                setLogged(true);

                if (onSetModal) {
                  onSetModal(res.data.showModal);
                  res.data.showModal = false;
                }

                sendDatalayerAuth(res.data)
                sendDatalayerPageView(res.data)
                localStorage.setItem("USER_INFO", JSON.stringify(res.data));

                const name = res.data.name.split(" ");
                setUserName(name[0]);
                setCountry(res.data.country);
              } else {
                window.location.href = siteUrl + "/autenticacion";
              }
            })
            .catch((e) => {
              if (localStorage.getItem("USER_INFO")) {
                setLogged(true);
                const userInfo = JSON.parse(localStorage.getItem("USER_INFO"));
                const name = userInfo.name.split(" ");
                setUserName(name[0]);
                setCountry(userInfo.country);
                sendDatalayerAuth(userInfo)
              } else {
                window.location.href = siteUrl + "/autenticacion";
              }
            });
        }
      }
    } else {
      setLogged(true);
      setUserName("Localhost");

      if (onSetModal) {
        onSetModal(true);
      }
    }
  }, []);

  const validateActiveModal = React.useCallback(
    () => {
      let actualPath = getWindow()?.location?.href || '';
      let siteUrl = process.env.SITE_URL
      let isHomeSection = actualPath === siteUrl || actualPath === `${siteUrl}/`

      let GenericModalFiltered = GenericModalsInfo.filter(
        modal => isHomeSection ? 
          modal.slugOId === "home" : 
          actualPath.includes(modal.slugOId)
      );

      GenericModalFiltered.length > 0 &&
        setModalActive(GenericModalFiltered[GenericModalFiltered.length - 1])
    },
    [GenericModalsInfo, setModalActive]
  )

  useEffect(() => {
    validateActiveModal()
  }, [validateActiveModal]);

  useEffect(() => {
    window.addEventListener("hashchange", validateActiveModal)

    return () => {
      window.removeEventListener("mousedown", validateActiveModal);
    }
  }, []);

  return (
    <>
      {logged ? (
        <>
          <Header userName={userName} />

          <div>
            <main className="f-main-container">{children}</main>
            <Footer country={country} />
          </div>

          {modalActive && (
            <GenericModal modalInfo={modalActive} onClose={closeGenericModal} />
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Layout;
