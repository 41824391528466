export const dateFormat = (date, type = "numeric") => {
  const d = new Date(date)
  const ye = new Intl.DateTimeFormat("es", { year: "numeric" }).format(d)
  const da = new Intl.DateTimeFormat("es", { day: "2-digit" }).format(d)

  if (type === "long") {
    const mo = new Intl.DateTimeFormat("es", { month: "long" }).format(d)
    return `${da} de ${mo} ${ye}`
  } else {
    const d = new Date(date)
    const mo = new Intl.DateTimeFormat("es", { month: "numeric" }).format(d)
    return `${da}/${mo}/${ye}`
  }
}

export const createDate = (date) => {
  let newDate = new Date(date || Date.now())
  newDate.setHours(0, 0, 0)

  return newDate
}

// return date in object format { year, month, day } moths are 1 to 12 based
export const getDateToObject = (d, day) => {
  const data = {
    day: day === 0 ? day : d.getDate(),
    month: d.getMonth() + 1,
    year: d.getFullYear(),
  };
  return data;
};
