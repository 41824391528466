import React from "react";
import './section-the-new.scss'
import Card from "../../../shared/components/atoms/card/card";
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import ArrowSlider from "../../../shared/components/atoms/arrow-slider/arrow-slider";
import { graphql, useStaticQuery } from "gatsby";
import { getNewArticles } from "../../../shared/utils/getNewArticles";

const SectionTheNew = () => {

  const { allContentfulFamiliaInstitucionalArticulosCards, allContentfulFamiliaInstitucionalHome } = useStaticQuery(
    graphql`
      query Cards_Query {
        allContentfulFamiliaInstitucionalHome {
          nodes {
            tituloLoNuevo
          }
        }
        allContentfulFamiliaInstitucionalArticulosCards(sort: { order: DESC, fields: fechaDePublicacionCard }, filter: {tipoDeArticulo: {ne: "Capsula"}}, limit: 10) {
          nodes {
            Slug
            tituloCard
            tipoDeContenido {
              nombreCategoria
            }
            redireccionExterna
            descripcionCard {
              raw
            }
            fechaDePublicacionCard
            tags {
              titulo
              nombreTag
            }
            imagenCard {
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
              }
              description
            }
            prioridadCard
            categoriaCard {
              nombreCategoria
            }
          }
        }
      }
    `
  )

  let contentInfo = allContentfulFamiliaInstitucionalArticulosCards.nodes
  contentInfo = getNewArticles(contentInfo)

  const tituloLoNuevo = allContentfulFamiliaInstitucionalHome.nodes[0].tituloLoNuevo

  const settingsSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <ArrowSlider direction="right" />,
    prevArrow: <ArrowSlider direction="left" />,
    swipe: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }

  return (
    <section>
      <section className="f-section-the-new">
        <div className="f-section-the-new__wrapper">
          <h2 className="f-section-the-new__title">{tituloLoNuevo}</h2>

          <div className="f-section-the-new__cards">
            <Slider {...settingsSlider}>
              {contentInfo &&
                contentInfo.map((data, index) => {
                  const category = data.categoriaCard.nombreCategoria
                  const preSlug = (category === 'Transversal') ? 'lo-nuevo/' : 'territorios/'
                  return (
                    <Card content={data} key={index} type="article" preSlug={preSlug}/>
                  )
                })
              }
            </Slider>
          </div>

        </div>
      </section>
    </section>
  )

}

export default SectionTheNew
