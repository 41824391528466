import React, { useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactPlayer from "react-player";
import useMobile from "../../../hooks/useMobile";
import Modal from "../modal/modal";
import './genericModal.scss'

const WrapperModal = ({redireccionModal, ...props}) => redireccionModal ?
<a 
  href={redireccionModal}
  target={redireccionModal.includes("https") ? "_blank" : "_self"}
  {...props}
>
  {props.children}
</a> :
<div {...props}>
  {props.children}
</div>

const GenericModal = ({ onClose = () => { }, modalInfo, scrollHidden = false, setTagVideo = () => { }, handleProgress = () => {}, urlVideoJwp = null }) => {

  const isMobile = useMobile();
  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("overflow-body-hidden");
    return () => {
      body.classList.remove("overflow-body-hidden");
    }
  }, [])
  const dataLayerHome= function datalayer(){
    const urlActual = window.location;
    if(urlActual.hash===""){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({event:'ga_event',
      category:'PopUp',
      action:'Click',
      label:'¡Quiero responder!',
      value:''});
    }
  }
  return (
    <Modal setShowModal={onClose} wrapperClass="f-generic-modal-wrapper">
      <WrapperModal id="modalVideoIframe" className={scrollHidden? "f-generic-modal f-generic-modal--without-scroll" : "f-generic-modal"} redireccionModal={modalInfo.redireccionModal || null}>
        {modalInfo.urlVideo?.includes("https") || urlVideoJwp  ? (
          <div className="f-generic-modal__video">
            {modalInfo.urlVideo?.includes("jwplatform") ? (
              <video
                className="f-generic-modal__video__local"
                src={modalInfo.urlVideo}
                controls
              />
            ) : modalInfo.urlVideo?.includes("youtube") || modalInfo.urlVideo?.includes("youtu.be") || urlVideoJwp ? (
              <ReactPlayer
                className="f-generic-modal__video__youtube"
                width="484px"
                height="65%"
                url={urlVideoJwp || modalInfo.urlVideo}
                controls
                onPlay={() => setTagVideo("Start")}
                onEnded={() => setTagVideo("Complete")}
                onProgress={handleProgress}
                progressInterval={1000} 
              />
             
            ) : (
              <iframe
              frameBorder="0"
              scrolling="no"
              src={modalInfo.urlVideo}
              sandbox="allow-popups allow-same-origin allow-scripts"
              className="f-generic-modal__video__iframe"
              allowFullScreen
              style={{ aspectRatio: "16 / 9" }}
                
              />
            )}
          </div>
        ) : modalInfo.imagenModal[isMobile ? 1 : 0].gatsbyImageData ? (
          <GatsbyImage
            image={modalInfo.imagenModal[isMobile ? 1 : 0].gatsbyImageData}
            alt={modalInfo.imagenModal[isMobile ? 1 : 0].description}
            onClick={()=>{dataLayerHome()}}
          />
        ) : (
          <img
            src={modalInfo.imagenModal[isMobile ? 1 : 0].file?.url}
            alt={modalInfo.imagenModal[isMobile ? 1 : 0].desription}
            onClick={()=>{dataLayerHome()}}
          />
        )}
      </WrapperModal>
    </Modal>
  )
}

export { GenericModal }
