import React, {useEffect, useRef, useState} from "react";
import {graphql, useStaticQuery} from "gatsby";
import CardArticle from "../../atoms/card-article/card-article";
import Pagination from "../../atoms/pagination/pagination";

import useMobile from "../../../hooks/useMobile";

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import './pagination-articles.scss'

import iconResults from './../../../assets/icons/icon-no-filter-results.svg'
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import optionsTextEnriched from "../../../utils/optionsTextEnriched";

const PaginationArticles = ({articles, isSearch = false, showFavorites, setShowFavorites, category}) => {
  const {allContentfulFamiliaInstitucionalMensajesDeUsuario} = useStaticQuery(
    graphql`
        query NoResultsQuery {
            allContentfulFamiliaInstitucionalMensajesDeUsuario {
                nodes {
                    resultTituloBusquedaFiltro {
                        raw
                    }
                    resultDescripcionBusquedaFiltro {
                        raw
                    }
                }
            }
        }
    `
  )

  const userMessage = allContentfulFamiliaInstitucionalMensajesDeUsuario.nodes[0]

  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    //Restaurar el slide en la pagina 1 cuando se realize un filtro
    setCurrentSlide(0)
    sliderRef.current.slickGoTo(0)
  }, [articles])

  const sliderRef = useRef()
  const isMobile = useMobile()

  const numberOfArticles = isSearch ? 6 : 4

  const numberOfSlides = []
  for (let i = 0; i < Math.ceil((articles.length) / numberOfArticles); i++) {
    numberOfSlides.push(i)
  }

  const slideNext = () => {
    sliderRef.current.slickGoTo(currentSlide + 1)
    handleScrolltop()
  }

  const slidePrev = () => {
    sliderRef.current.slickGoTo(currentSlide - 1)
    handleScrolltop()
  }
  const onClickPageInfo = (page) => {
    sliderRef.current.slickGoTo(page - 1)
    handleScrolltop()
  }

  const handleScrolltop = () => {
    if (document.getElementById('scrollTop')) {
      document.getElementById('scrollTop').click()
    }
  }

  const optionsSlide = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    beforeChange: (current, next) => setCurrentSlide(next),
  }


  return (
    <section className={`f-pagination-articles ${isSearch && 'results-search'}`}>
      {!isSearch && (
        <div className="f-pagination-articles__favorites">
          <div className={`f-pagination-articles__favorites-copy ${showFavorites ? 'active' : ''}`}><i
            className="fas fa-heart"/><p>Favoritos</p></div>
          <label className={`f-pagination-articles__favorites-switch ${showFavorites ? 'active' : ''}`}
                 onClick={() => setShowFavorites(!showFavorites)}
          >
            <div className={`f-pagination-articles__favorites-slider ${showFavorites ? 'active' : ''}`}/>
          </label>
        </div>

      )}
      <Slider ref={sliderRef} className="f-slider" {...optionsSlide}>
        {numberOfSlides.map((slide, indexSlide) => {
          return (
            <div key={indexSlide} className="f-pagination-articles__content">
              {articles &&
              articles.map((article, index) => {
                if ((indexSlide + 1) * numberOfArticles > index && (((indexSlide + 1) * numberOfArticles) - numberOfArticles) <= index) {
                  const category = article.categoriaCard.nombreCategoria
                  const preSlug = (category === 'Transversal') ? 'lo-nuevo/' : 'territorios/'
                  return (
                    <CardArticle content={article} key={article.contentful_id} type="article" preSlug={preSlug}
                                 isSearch={isSearch} section="pagination" category={category}/>
                  )
                }
              })
              }
            </div>
          )
        })}
      </Slider>

      {!numberOfSlides.length && !isSearch &&
      <div className="f-pagination-articles__no-results">
        <img src={iconResults} alt="Sin resultados"/>
        <div
          className="f-pagination-articles__no-results-title"> {documentToReactComponents(JSON.parse(userMessage.resultTituloBusquedaFiltro.raw), optionsTextEnriched)} </div>
        <div
          className="f-pagination-articles__no-results-description"> {documentToReactComponents(JSON.parse(userMessage.resultDescripcionBusquedaFiltro.raw), optionsTextEnriched)} </div>
      </div>
      }

      {
        numberOfSlides.length > 1 &&
        (
          <Pagination
            totalPages={numberOfSlides.length}
            onClickNext={slideNext}
            onClickPrev={slidePrev}
            currentPage={currentSlide + 1}
            onClickPage={onClickPageInfo}
          />
        )

      }

      <a id="scrollTop" href='#scroll-top'></a>
    </section>
  )
}

export default PaginationArticles

